import { AssetTypes as AT } from "@/.graphql/graphql";

// region грязный хак, для того чтобы не импортировать напрямую этот энум из генеренного кода.
import AssetTypes = AT;
import { Coordinates } from '@workspace/4Z1.ts.utils';
export {
  AssetTypes
}
// endregion

export enum KnownAssetUavTypes {
  Lancet = '22',
  UBoat = '8'
}

export interface Asset {
  readonly projectId?: string;
  readonly projectName?: string;
  readonly id: string;
  readonly publicAssetId: string;
  readonly isRevoked: boolean;
  readonly name: string;
  readonly comment: string;
  readonly project: string;
  readonly assetType: AssetTypes;
  readonly iconId: string;
  readonly assetCapabilities: readonly any[]; // TODO any
  readonly nearestPlace: string;
  readonly assetGroup: { readonly name: string, readonly id: string }; // TODO extract type
  readonly state: any; // TODO any
  readonly meta: readonly { readonly key: string, readonly value: string}[]; // TODO extract type
}

export interface AssetListFilter {
  readonly types: 'all' | readonly AssetTypes[];
  readonly project: 'all' | string;
  readonly onlyActive: boolean;
  readonly onlyRevoked: boolean
}

export interface UpdateDirectionReqParams {
  readonly asset: Asset;
  readonly direction: number;
}

/** Этот enum напрямую связан с AssetListFilter, при внесении изменений будьте осторожны */
export enum AssetFilterParams {
  Types = 'types',
  Project = 'project',
  OnlyActive = 'onlyActive',
  OnlyRevoked = 'onlyRevoked',
}

export type AssetFilterParam = Record<AssetFilterParams, string | string[] | boolean | readonly AssetTypes[]>

export interface UpdateCoordsReqParams {
  readonly asset: Asset;
  readonly coordinates: Coordinates;
}

/**
 * Список главных ассет-тайпов.
 *
 * TODO понять что это такое и почему это подмножество выделяется среди прочих
 */
export const ALL_ASSET_TYPES = [AssetTypes.Gcs, AssetTypes.Car, AssetTypes.GeoGraphics, AssetTypes.Ptz, AssetTypes.Uav, AssetTypes.Beacon];

