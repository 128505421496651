import moment from 'moment';

import map from './en-US/map';
import menu from './en-US/menu';
import modules from './en-US/module';
import user from './en-US/user';
import login from './en-US/login';
import violation from './en-US/violation';
import flight from './en-US/flight';
import video from './en-US/video';
import photo from './en-US/photo';
import online from './en-US/online';
import ofp from './en-US/ofp';
import gaz from './en-US/gaz';
import massload from './en-US/massload';
import statistic from './en-US/statistic';
import route from './en-US/route';
import tile from './en-US/tile';
import report from './en-US/report';
import order from './en-US/order';
import contextmenu from './en-US/contextmenu';
import access from './en-US/access';
import sk42 from './en-US/sk42';
import mark from './en-US/mark';
import asset from './en-US/asset';
import layer from './en-US/layer';
import dtoe from './en-US/dtoe';
import ftp from './en-US/ftp';
import changeAssetCoordinates from './en-US/change-asset-coordinates';
import revokedevice from './en-US/revoke-device';
import invertActions from './en-US/invert-actions';
import changeDir from './en-US/change-asset-direction';
import tabs from './en-US/tabs';
import confirmation from './ru-RU/confirmation';
import download from './en-US/download';

export default {
  'navBar.lang': 'Languages',
  'button.Save': 'Save',
  'button.More': 'More',
  'button.Skip': 'Skip',
  'button.Upload': 'Choose a folder',
  'button.UploadUSB': 'Upload USB',
  'button.UploadImage': 'Add image',
  'button.UploadFiles': 'Add images',
  'button.Arhive': 'Upload archive',
  'button.Abort': 'Cancel',
  'button.downloadStartSoon': 'The download will begin soon. Large files and lists may take longer to load than usual',
  'button.Edit': 'Edit',
  'button.Delete': 'Delete',
  'button.Cancel': 'Cancel',
  'button.Create': 'Create',
  'button.Add': 'Add',
  'button.View': 'View',
  'button.Download': 'Download',
  'button.Access': 'Access',
  'button.Apply': 'Apply',
  'button.DeleteAll': 'Delete all',
  'button.SelectAll': 'Select all',
  'button.Reset': 'Reset',
  'button.Select': 'Select',
  'button.AllPhotos': 'All photos',
  'button.selected': '{count, plural, =0 {no objects} one {{count} object selected} other {{count} objects selected}}',
  'button.startTiffProcessing': 'Start processing GeoTiff',
  'button.AddFiles': 'Add files',
  'button.DownloadAllPhotos': 'Download photos',
  'button.FTP': 'FTP',
  'button.DownloadKml': 'KML',
  videos: 'Videos',
  photos: 'Photos',
  gases: 'Gas analyzer',
  flight: 'Flight',
  'Action': 'Action',
  'pc.': 'pc.',
  from: 'from',
  'coordinates.lon': 'Longitude',
  'coordinates.lat': 'Latitude',
  'ranges.Today': 'Today',
  'ranges.This Month': moment.months(moment().month()),
  'ranges.Month': 'Month',
  'ranges.Year': 'Year',
  Loading: 'Loading',
  'Sure to delete?': 'Sure to delete?',
  'Please Input': 'Please Input',
  'Please select ofp': 'Please, select orthomosaic',
  Undefined: 'Undefined',
  'home.subtitle': 'Uploaded data for the last 2 days',
  change_date: 'Change range',
  SelectAll: 'Select all',
  'license.active.expires': 'License expires',
  'license.invalid': 'License not active',
  'license.inactive': 'License invalid',
  'license.active': '{message} v{version}',
  'input.helper.typeNumber': 'Type number',
  'input.helper.typeText': 'Type text',
  403: 'Forbidden',
  404: 'Sorry, the page you visited does not exist',
  'Empty': 'This page is empty now. Check back later.',
  'CheckLater': 'Check back later',
  Preview: 'Preview',
  Remove: 'Remove',
  holdposition: 'Hold zoom',
  rows: 'rows',
  Selected: 'Selected',
  ...tabs,
  ...map,
  ...menu,
  ...user,
  ...login,
  ...violation,
  ...flight,
  ...photo,
  ...video,
  ...online,
  ...ofp,
  ...gaz,
  ...modules,
  ...massload,
  ...statistic,
  ...route,
  ...tile,
  ...report,
  ...order,
  ...contextmenu,
  ...access,
  ...sk42,
  ...mark,
  ...asset,
  ...layer,
  ...dtoe,
  ...ftp,
  ...changeAssetCoordinates,
  ...revokedevice,
  ...invertActions,
  ...changeDir,
  ...confirmation,
  ...download,
};
