export enum Layers {
  Maps = 'maps',
  Vprf = 'vprf',
  Traffic = 'traffic',
  Routes = 'routes',
  Tiles = 'tiles',
  Ofps = 'ofps',
  Flights = 'flights',
  Photos = 'photos',
  Videos = 'videos',
  Ofp = 'ofp',
  Ofp_compare = 'ofp_compare',
  Violations = 'violations',
  Zviolations = 'zviolations',
  Marks = 'marks',
  Sk42grid = 'sk42grid',
  Onlines = 'onlines_global',
}
/** Слой отвечающий за отображение видимой зоны Совы */
export const FIELD_OF_VIEW_LAYER = 'fieldOfViewLayer';

export type LayerType = Layers | typeof FIELD_OF_VIEW_LAYER;

export const MAP_LAYERS:  ReadonlyArray<LayerType> = [
  Layers.Marks,
  Layers.Routes,
  Layers.Tiles,
  Layers.Onlines,
  Layers.Flights,
  Layers.Violations,
  Layers.Ofps,
  Layers.Vprf,
  Layers.Traffic,
  Layers.Sk42grid,
  FIELD_OF_VIEW_LAYER
];

export const OSM_MAP_ID = -1;

export enum LayersFlags {
  /**
   * Определяет отображение/скрытие вложенных элементов слоя на панели слоев карты
   *
   * Важно! - Используется на старых слоях, чтобы скрыть возможность открытия/закрытия группы слоёв в дропдауне на панели слоёв
   *
   * Убрать, когда все старые слои, обернутые в LayerGroup, будут переписаны
   *
   * @type {(boolean)}
  */
  DisableChildrenVisibilityChange = 'disable-children-visibility-change',
}

/**
 * Внимание! Эти настройки актуальны только для нового компонента карты.
 * При внесении изменений тут не забудьте убедиться, что соответствующие изменения сделаны и для старых карт.
 * Порядок старых слоев настраивается в компоненте @/components/Map/MapPanel/LayerPanel.tsx!
 */
export const ONLINE_PAGE_LAYERS_ORDER: ReadonlyArray<LayerType> = [
  Layers.Vprf,
  Layers.Traffic,
  Layers.Routes,
  Layers.Tiles,
  Layers.Ofps,
  Layers.Violations,
  Layers.Marks,
  Layers.Onlines,
];

export const FLIGHTS_PAGE_LAYERS_ORDER: ReadonlyArray<LayerType> = [
  Layers.Routes,
  Layers.Tiles,
  Layers.Ofps,
  Layers.Flights,
  Layers.Violations,
  Layers.Marks,
  Layers.Onlines,
];
