/**
 * Статус загрузки файла
 */
export enum UploadingFileStates {
  Running,
  Failed,
  Queued,
  Completed,
  Error,
}

export interface FileUpload {
  readonly fileName: string;
  readonly state: UploadingFileStates;
  readonly error?: string;
  readonly size?: number;
  readonly startDate?: Date;
  readonly progress: number;
  readonly onClose?: () => void;
  readonly closeButtonTooltip?: string;
  readonly networkError?: string;
}

export interface UploadSession {
  readonly type: 'files' | 'usb';
  readonly id: string;
  readonly bytesToUpload: number;
  readonly bytesUploaded: number;
  readonly files: readonly FileUpload[];
  readonly isFinished: boolean;
  readonly isStarting: boolean;
  readonly requestErrorMessage: string | undefined;
  readonly onClose?: () => void;
}

// region

/// !!! Внимание - ниже копипаста на скорую руку решения из будущей версии. там это в отдельной фиче для карточки полета, тут я перенес в entities
// TODO: зарефачить, как решения окажутся в одной ветке.
// - вытащить все в одно место, убрать объявления перечислений и типов там где это не нужно
// - максимально переиспользовать код

export enum UploadFileStatus {
  /** Неизвестный статус */
  Unknown = 'Unknown',

  /** Старт загрузки в tus, создание записи в БД massload (tus-хук pre-create) */
 CreateUpload = 'CreateUpload',

  /** В процессе загрузки, интервальное обновление времени срабатывания хука post-create */
 Uploading = 'Uploading',

  /** Загружено в tus (директория /data, хук pre-finish) */
 Uploaded = 'Uploaded',

  /** Перемещено в uploads (директория /uploads, хук post-finish) */
 Stored = 'Stored',

  /** Обрабатывается отправка информации в АПИ PHP (не используется) */
 Processing = 'Processing',

  /**  Завершен (проставляется после обращения в REST-методу post:api/files, если на вход передается Success) */
 Finished = 'Finished',

  /** Отменен (проставляется после обращения в REST-методу post:api/files, если на вход передается _НЕ_ Success) */
 Rejected = 'Rejected',

  /**  Уничтожен (проставляется если сработал соответствующий хук от tus'a - post-terminate) */
 Terminated = 'Terminated',

  /** Удалён (проставляется при физическом удалении файла через соответствующий rest-метод delete) */
 Removed = 'Deleted',

 /** Выполнен перенос документа в архив оригиналов (S3 storage) */
 Archived = 'Archived',

 /** С ошибкой */
 Error = 'Error',
}

export enum UploadHistoryFileStatuses {
  Finished = 'Finished',
  Processing = 'Processing',
  Loading = 'Loading',
  Error = 'Error',
  Deleted = 'Deleted',
  InternalServerError = 'InternalServerError',
  Preprocessing = 'Preprocessing',
  Denied = 'Denied',
}

const FILE_STATUSES_MAP: Partial<Record<UploadFileStatus, UploadHistoryFileStatuses>> = {
  [UploadFileStatus.Unknown]: UploadHistoryFileStatuses.Error,
  [UploadFileStatus.CreateUpload]: UploadHistoryFileStatuses.Preprocessing,
  [UploadFileStatus.Uploading]: UploadHistoryFileStatuses.Loading,
  [UploadFileStatus.Uploaded]: UploadHistoryFileStatuses.Processing,
  [UploadFileStatus.Stored]: UploadHistoryFileStatuses.Processing,
  [UploadFileStatus.Processing]: UploadHistoryFileStatuses.Processing,
  [UploadFileStatus.Finished]: UploadHistoryFileStatuses.Finished,
  [UploadFileStatus.Error]: UploadHistoryFileStatuses.Error,
  [UploadFileStatus.Rejected]: UploadHistoryFileStatuses.InternalServerError,
  [UploadFileStatus.Terminated]: UploadHistoryFileStatuses.Denied,
  [UploadFileStatus.Removed]: UploadHistoryFileStatuses.Deleted,
  [UploadFileStatus.Archived]: UploadHistoryFileStatuses.Finished,
} as const;

// TODO переосмыслить что тут происходит - почему мы мапим одно множество статусов в другое и как это все должно называться
export function mapFileStatus(status: UploadFileStatus): UploadHistoryFileStatuses {
  return FILE_STATUSES_MAP[status] ?? UploadHistoryFileStatuses.Error;
}

// Сопоставление статуса файла с его локализованным текстовым ключом.
const FILE_LOCALES_BY_STATUS: Record<UploadHistoryFileStatuses, string> = {
  [UploadHistoryFileStatuses.Finished]: 'massload.status.Loaded',
  [UploadHistoryFileStatuses.Loading]: 'massload.status.Uploading',
  [UploadHistoryFileStatuses.Error]: 'massload.status.Error',
  [UploadHistoryFileStatuses.Denied]: 'massload.status.Denied',
  [UploadHistoryFileStatuses.Deleted]: 'massload.status.Deleted',
  [UploadHistoryFileStatuses.InternalServerError]: 'massload.status.InternalServerError',
  [UploadHistoryFileStatuses.Preprocessing]: 'massload.status.Processing',
  [UploadHistoryFileStatuses.Processing]: 'massload.status.Processing',
} as const;

export function mapFileStatusToMsgId(status: UploadFileStatus): string {
  const mapped = mapFileStatus(status);

  return FILE_LOCALES_BY_STATUS[mapped] 
    ?? mapped; // если такого ключа нет - покажем хотя бы статус
}


// endregion
