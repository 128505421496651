import { type FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import '@/demo.scss';
import {
  TooltipActions,
  TooltipOverlayStore,
} from '../model/tooltipOverlay.store';
import { getCursorPosition } from '../model/utils';

export const TooltipOverlay: FC<MapPluginProps> = observer(
  ({ map = implicitMapCheck() }) => {
    const info = useRef<HTMLDivElement>(null);
    const [store] = useState(() => new TooltipOverlayStore(map));

    useEffect(() => {
      const infoElement = info.current;

      if (infoElement === null) return;

      const tooltipActions: TooltipActions = {
        updateContent: (content) => {
          infoElement.innerHTML = content.join('<br />');
        },
        show: ({ x, y }, elementsCount) => {
          map.container.style.cursor = 'pointer';

          const elementWidth = infoElement.clientWidth;

          const position = getCursorPosition(x, y, elementWidth, elementsCount);

          infoElement.style.display = 'block';
          infoElement.style.left = `${position.x}px`;
          infoElement.style.top = `${position.y}px`;
        },
        hide: () => {
          map.container.style.cursor = '';
          infoElement.style.display = 'none';
          infoElement.innerHTML = '';
        },
      };

      store.mount(tooltipActions);

      return () => store.unmount();
    }, [map, info]);

    return <div id="info" style={{ display: 'none' }} ref={info}></div>;
  },
);
