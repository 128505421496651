import { MapEngine } from '@/shared/map/model/interfaces';
import { isNil } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { MapBrowserEvent } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Pixel } from 'ol/pixel';

interface HoverCursorPosition {
  readonly x: number;
  readonly y: number;
}

export interface TooltipActions {
  readonly updateContent: (lines: readonly string[]) => void;
  readonly show: (position: HoverCursorPosition, elementCount: number) => void;
  readonly hide: () => void;
}

export class TooltipOverlayStore {
  private actions: TooltipActions | undefined = undefined;

  constructor(private readonly map: MapEngine) {
    makeAutoObservable(this);
  }

  public mount(actions: TooltipActions): void {
    this.actions = actions;
    this.map.onPointerMove(this.handlePointerMove);
  }

  private handlePointerMove = (event: MapBrowserEvent<UIEvent>): void => {
    const names = this.collectFeatureNames(event.pixel);

    this.updateTooltipVisibility(names, event);
  };

  private collectFeatureNames(pixel: Pixel): readonly string[] {
    const names: string[] = [];

    this.map.forEachFeatureAtPixel(pixel, (feature) => {
      const cluster = feature.get('features');

      const featureNames = cluster
        ? this.collectClusterNames(cluster)
        : this.collectSingleFeatureName(feature);

      names.push(...featureNames);
    });

    return names;
  }

  private collectClusterNames(
    cluster: readonly FeatureLike[],
  ): readonly string[] {
    return cluster
      .map((feature: FeatureLike) => feature.get('name'))
      .filter((name) => !isNil(name));
  }

  private collectSingleFeatureName(feature: FeatureLike): readonly string[] {
    const featureName = feature.get('name');

    return featureName ? [featureName] : [];
  }

  private updateTooltipVisibility(
    names: readonly string[],
    event: MapBrowserEvent<UIEvent>,
  ): void {
    if (!this.actions) return;

    if (names.length > 0) {
      this.actions.updateContent(names);
      const { layerX, layerY } = event.originalEvent as MouseEvent;

      this.actions.show(
        {
          x: layerX,
          y: layerY,
        },
        names.length,
      );
    } else {
      this.actions.hide();
    }
  }

  public unmount(): void {
    this.actions = undefined;
    this.map.offPointerMove(this.handlePointerMove);
  }
}
