import './styles.scss';

import { FC, ReactNode } from 'react';

import { Header } from '@workspace/4Z1.uikit.react';
import { UserMenu } from '@/widgets/UserMenu';
import TotalPercentUpload from '@/widgets/TotalPercentUpload/TotalPercentUpload';

interface Props {
  readonly children: ReactNode;
  readonly sidebarContent?: ReactNode;
  readonly headerLeft?: ReactNode;
  readonly headerRight?: ReactNode;
  readonly headerFixed?: boolean;
}

/**
 * Использование старого компонента BaseAppLayout при разработке новых страниц - запрещено
 *
 * Подробное описание мотивации разделения см. в описании к компоненту ./BaseAppLayout
 *
 * @see ./BaseAppLayout.tsx
 */
const PageLayout: FC<Props> = ({
  children,
  headerLeft,
  headerRight,
  headerFixed,
  sidebarContent,
}) => {
  return (
    <div className="pageLayout">
      {sidebarContent && (
        <div className={'sidebarLeft'}> {sidebarContent} </div>
      )}
      <div className="container">
        <Header
          className="header"
          fixed={headerFixed}
          childrenLeft={headerLeft}
          childrenRight={
            <>
              {headerRight}
              <TotalPercentUpload />
              <UserMenu />
            </>
          }
        />

        {children}
      </div>
    </div>
  );
};

export default PageLayout;
