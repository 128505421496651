import { Base } from './Base';
import { Onlines } from './Onlines';

/** Библиотека шаблонов карт */
let Maps = {
  Base: Base,
  Onlines: Onlines,
}

export { Maps }
