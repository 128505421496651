export default {
  'video.start': 'Взлет',
  'video.end': 'Посадка',
  'video.miniap': 'Миникарта',
  'video.fullScreen': 'Полный экран',
  'video.close': 'Закрыть окно',
  'video.inverse': 'Инверсия камеры',
  'video.error': 'Трансляция прервалась. Идет процесс переподключения...',
  'video.loadingBroadcasts': 'Идёт загрузка трансляций...',
};
