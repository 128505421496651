export type LocationCoordinates = [number, number]

export enum LocalStorageKeys {
  ClientLocale = 'client_locale',
  CurrentOptions = 'currentOptions',
  CurrentUser = 'currentUser',
  HiddenFOV = 'hiddenFieldOfView',
  UserAvatarColor = 'userAvatarColor',
  Demo = 'demo',
}

export interface Location {
  readonly latitude: number;
  readonly longitude: number;
}

export enum Languages {
  RU = 'ru-RU',
  EN = 'en-EN'
}

export interface Locale {
  readonly code: Languages;
  readonly label: string;
}

export type AsyncData<T> = {
  data: T,
  error?: never,
  isLoading?: never,
} | {
  data?: never,
  error: Error | string,
  isLoading?: never,
} | {
  data?: never,
  error?: never,
  isLoading: boolean,
};


// TODO определить в один неймспейс
export function asyncDataValue<T>(value: T): AsyncData<T> {
  return { data: value };
}

export function asyncDataLoading<T>(): AsyncData<T> {
  return { isLoading: true };
}
export function asyncDataError<T>(error: Error | string): AsyncData<T> {
  return { error: error };
}

