import { IObservableArray, makeAutoObservable, observable } from 'mobx';
import { TusApi } from '../api/tus.api';
import { inject, injectable } from 'inversify';
import { logger } from '@workspace/4Z1.ts.utils';
import { type MassloadApi } from '@/entities/fileUpload';
import { DiKeys } from '@/shared/di/global';
import { UploadSession } from './interface';
import { TusUploadSession } from './upload.session';
import { UsbUploadSession } from './usb.upload.session';
import { type UsbUploadApi } from '../api/usb.upload.api';

const log = logger('UPLD:SRV');

export type UploadStartParams =
  | {
    readonly type: 'files',
    readonly files: readonly File[],
  }
  | {
    readonly type: 'usb',
  };

@injectable()
export class UploadService {
  public static readonly diKey = Symbol.for('UploadService');

  private readonly sessions: IObservableArray<UploadSession> = observable.array();
  private readonly tus: TusApi;
  private readonly massloadApi: MassloadApi;
  private readonly usbApi: UsbUploadApi;

  constructor(
    @inject(TusApi.diKey) tus: TusApi,
    @inject(DiKeys.api.massload) massloadApi: MassloadApi,
    @inject(DiKeys.api.usbupload) usbApi: UsbUploadApi,
  ) {
    this.tus = tus;
    this.massloadApi = massloadApi;
    this.usbApi = usbApi;
    makeAutoObservable(this);
  }

  public uploadFiles(params: UploadStartParams): void {
    const session = this.createSession(params);
    log.debug('new upload started', session.id);
    this.sessions.push(session);
  }

  /**
   * Удаляет ВСЕ загрузки, если ВСЕ они завершены
   */
  public cleanUp() {
    log.debug('cleanUp');
    const allSessionsFinished = this.sessions.every(session => session.isFinished);
    if (allSessionsFinished) {
      this.sessions.clear();
      log.debug('cleanUp :: done');
    }
    this.updateBeforeUnloadHandler();
  }

  /**
   * Удаляет ВСЕ USB-загрузки, если ВСЕ они завершены
   */
  public cleanUsb() {
    const usbSessions = this.sessions.filter(session => session.type === 'usb');
    const usbSessionsFinished = usbSessions.every(session => session.isFinished);

    if (usbSessionsFinished) {
      this.sessions.forEach((session, index) => {
        if (session.type === 'usb') {
          this.sessions.splice(index, 1);
        }
      });
      log.debug('cleanUsb :: done');
      this.updateBeforeUnloadHandler();
    }
  }

  public get hasNewSessions(): boolean {
    return this.sessions.some(session => session.isStarting);
  }

  public get allSessions(): readonly UploadSession[] {
    log.debug('uploads');
    return [ ...this.sessions ];
  }

  /**
   * Общий процент по всем загрузкам
   */
  public get percentage() {
    /**TODO Удалить костыль!!!
    Это должно вызываться в другом месте
    ========================*/
    this.updateBeforeUnloadHandler();
    //=========================

    const { uploaded, total } = this.sessions.reduce((acc, session) => {
      acc.total += session.bytesToUpload;
      acc.uploaded += session.bytesUploaded;
      return acc;
    }, { uploaded: 0, total: 0 });

    return total === 0 ? 0 : uploaded / total * 100;
  }

  private createSession(params: UploadStartParams): UploadSession {
    if (params.type === 'files') {
      return new TusUploadSession(params.files, this.tus, this.massloadApi);
    }
    if (params.type === 'usb') {
      return new UsbUploadSession(this.usbApi);
    }
    throw new Error('Unknown upload type: ' + params);
  }

  private handleBeforeUnload(event: BeforeUnloadEvent): string | undefined {
    event.preventDefault();
    event.returnValue = '';
    return;
  }

  private updateBeforeUnloadHandler(): void {
    const hasActiveUploads = this.sessions.some(session => !session.isFinished);
    if (hasActiveUploads) {
      log.debug('Setting onbeforeunload handler');
      window.onbeforeunload = this.handleBeforeUnload;
    } else {
      log.debug('Clearing onbeforeunload handler');
      window.onbeforeunload = null;
    }
  }
}