import { getAuthToken } from '@/shared/utils/getAuthToken';
import {
  DetailedError,
  Upload,
  UploadOptions 
} from 'tus-js-client';
import { injectable } from 'inversify';
import { logger } from '@workspace/4Z1.ts.utils';
import { parseErrorKey } from '../utils/parseErrorKey';
import { getLocalizationId } from '../utils/getLocalizationId';
import { isNil } from 'lodash';

// TODO нужно придумать способ подменить массив сущностью, которая будет делать то что нам нужно и не занимать так много памяти )
export const RETRY_DELAYS: readonly number[] = Array.from({ length: 10000 }).map((_, index) => 2000);

const log = logger('TUS:A');

export interface FileUploadRequest {
  readonly id: string;
  readonly file: File;
}

@injectable()
export class TusApi {
  public static readonly diKey = Symbol.for('TusApi');

  private readonly api: string = FILE_UPLOADER;
  private uploads: Map<string, Upload> = new Map();

  public upload(
    loadId: string,
    files: readonly FileUploadRequest[],
    totalFiles: number,
    onProgress: (id: string, bytesUploaded: number, bytesTotal: number) => void,
    onError: (id: string, error: Error) => void,
    onNetworkError: (error: Error) => void,
    onSuccess: (id: string) => void,
  ): void {
    const totalSize = files.reduce((acc, file) => acc + file.file.size, 0);

    files.forEach(file => {
      const upload = new Upload(file.file, {
        headers: {
          loadId,
          fileCount: totalFiles.toString(),
          Authorization: `Bearer ${getAuthToken()}`,
        },
        ...this.createTusOptions(file.file, totalSize),
        onError: (error: Error) => {
          log.debug('upload error', file.id, error);
          this.uploads.delete(file.id);

          // Парсинг ошибки
          const jsonMatch = error.message.match(/response text: (\{.*\})/);
          if (isNil(jsonMatch) || jsonMatch.length < 2) {
            onError(file.id, { message: undefined });
            return;
          }

          const parseError = JSON.parse(jsonMatch[1]);
          const errorMessage = parseError.message;
          const errorCode = parseErrorKey(errorMessage);
          const localizationId = getLocalizationId(errorCode);
        
          // Возвращаем объект с полем message
          onError(file.id, { message: localizationId });

        },
        onProgress: (bytesUploaded: number, bytesTotal: number) => {
          log.debug('upload update', file.id, bytesUploaded, bytesTotal);
          onProgress(file.id, bytesUploaded, bytesTotal);
        },
        onSuccess: () => {
          log.debug('upload done', file.id);
          onSuccess(file.id);
          this.uploads.delete(file.id);
        },
        onShouldRetry: (err: DetailedError, retryAttempt: number, options: UploadOptions): boolean => {
          const status = err.originalResponse ? err.originalResponse.getStatus() : 0;
          log.warn('upload error', status, retryAttempt, loadId, err);
  
          if (status === 0 || status >= 500) {
            const errorCode = parseErrorKey('ERR_NETWORK');

            const localizationId = getLocalizationId(errorCode);
            onNetworkError({ message: localizationId });
          }

          if ([403, 401, 413, 400].includes(status)) {
            log.info('upload cancelled', loadId);
            return false;
          }
  
          log.info('upload resumed', loadId);
          return true;
        }
      });
      this.uploads.set(file.id, upload);
      upload.start();
    });
  }

  public abortFile(fileRequestId: string): void {
    const upload = this.uploads.get(fileRequestId);
    if (upload !== undefined) {
      upload.abort(true);
      this.uploads.delete(fileRequestId);
    }
  }

  private createTusOptions(
    file: File,
    totalSize: number,
  ): UploadOptions {
    return {
      endpoint: this.api,
      retryDelays: [...RETRY_DELAYS],
      metadata: {
        filename: file.name,
        filetype: file.type,
        totalSize: totalSize.toString(),
        relativePath: file.webkitRelativePath,
      },
    };
  }
}