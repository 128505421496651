import { Control } from 'ol/control';
import LayerGroup from 'ol/layer/Group';
import Feature, { FeatureLike } from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import { Draw, Interaction } from 'ol/interaction';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { Pixel } from 'ol/pixel';
import { MapMarker } from './markers';
import { View } from 'ol';
import BaseLayer, { Options as OlLayerBaseOptions } from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import TileSource from 'ol/source/Tile';
import { Collection, Overlay } from 'ol';

export interface MapPluginProps {
  readonly map?: MapEngine;
}

type CallbackOnClickFeature = (e: MapBrowserEvent<UIEvent>) => void;

export interface MapsDocument {
  id: string;
  name: string;
  url: string;
  type: string;
  active: number;
}

export interface LayerOptions extends OlLayerBaseOptions{
  /** Название слоя, которое будет отображаться в <LayerPanel /> */
  title?: string;
  /** Имя слоя */
  name?: string;
}

/**
 * Интерфейс движка карты
 */
export interface MapEngine {

  /**
   * Базовый слой карты
   */
  readonly osmLayer: TileLayer<TileSource>;

  /**
   * Представление карты
   */
  readonly view: View;

  /**
   * Метод внедрения карты в DOM дерево
   */
  init(): void;

  /**
   * Установить слой базовой карты
   */
  setBaseMapLayer(layer: BaseLayer): void;

  /**
   * Метод центрирования карты
   */
  setCenter(center: Coordinates, animate?: boolean): void;

  /**
   * Добавление нового контрола на карту
   */
  addControls(control: Control): void;

  /**
   * Добавление нового слоя
   */
  addLayer(
    layer: LayerGroup | VectorLayer<VectorSource<Feature<Geometry>>>,
  ): void;

  /**
   * Добавляет группу слоев на карту.
   */
  addLayerGroups(layers: BaseLayer[], options?: LayerOptions): void;

  /**
   * Удаление слоя с карты по имени
   */
  removeLayerByName(name: string): void;

  /**
   * Установка вида для карты
   */
  setView(view: View): void;

  /**
   * DOM элемент в котором интегрирована Карта
   */
  readonly container: HTMLElement;

  /**
   * Возвращает географические координаты точки, по которой был сделан клик
   */
  getCoordinateFromPixel(pixel: Pixel): Coordinates | undefined;

  /**
   * Возвращает коллекцию слоев, связанных с картой.
   */
  getLayers(): BaseLayer[];

  /**
   * Значение текущего зума
   */
  readonly zoom: number;

  /**
   * Метод приближение зума
   */
  zoomIn(): void;

  /**
   * Метод отдаление зума
   */
  zoomOut(): void;

  /**
   * Подписка клика на фичуу
   */
  onClickFeature(
    target: string,
    callback: (feature: FeatureLike) => void,
  ): CallbackOnClickFeature;

  /**
   * Добавление взаимодействий с картой
   */
  addInteraction(interaction: Interaction | Draw): void;

  /**
   * Удаление взаимодействий с карты
   */
  removeInteraction(interaction: Interaction | Draw): void;

  /**
   * Добавление маркера на карту
   */
  addMarker(data: MapMarker): void;

  /**
   * Удаление маркера с карты
   */
  deleteMarker(data: MapMarker): void;

  /**
   * Очищение слоя карты
   */
  clearLayer(layerId: string): void;

  /**
   * Получение списка фич по событию клика мыши на карте
   */
  getFeaturesByUiEvent(event: UIEvent): FeatureLike[];

  /**
   * Получение фичи по событию клика мыши на карте
   */
  getFeatureByUIEvent(
    event: UIEvent,
    param: string,
    value: string,
  ): FeatureLike | undefined;

  /**
   * Получение Координат по событию клика мыши на карте
   */
  getCoordinatesByUIEvent(event: UIEvent): Coordinates | undefined;

  /**
   * Формирование карты по модели MapsDocument, приходящей с бэкенда
   * @param layer
   * @param visible
   */
  createMapLayer(layer: MapsDocument, visible: boolean): TileLayer<TileSource>

  /**
   * Получение всех Overlay на карте
   */
  getOverlays(): Collection<Overlay>;

  /**
   * Получение Overlay по идентификатору
   */
  getOverlayById(listIds: string | number): Overlay;

  /**
   * Добавление Overlay
   */
  addOverlay(overlay: Overlay): void;

  /**
   * Удаление Overlays по списку идентификаторов
   */
  clearOverlaysList(listIds: Set<string>): void;

  /**
   * Удаление Overlay
   */
  removeOverlay(overlay: Overlay): void;

  /**
   * Обработчик события движения мыши на карте
  */
  onPointerMove(callback: (e: MapBrowserEvent<UIEvent>) => void): void;

  /**
   * Функция отписки от события движения мышью
  */
  offPointerMove(callback: (e: MapBrowserEvent<any>) => void): void;

  /**
   * Подписка на событие клика на карте
  */
  onMapClick(callback: (e: MapBrowserEvent<UIEvent>) => void): void;

  /**
   * Отписка от события клика на карте
  */
  offMapClick(callback: (event: MapBrowserEvent<any>) => void): void;

  /**
   * Проверяет, есть ли фича в точке
  */
  hasFeatureAtPixel(pixel: Pixel): boolean;

  /**
   * Выполняем действие в колбеке для каждой фичи в точке
  */
  forEachFeatureAtPixel(pixel: Pixel, callback: (feature: FeatureLike) => void): void;

  /**
   * Возвращает слой по указанному имени, по всему дереву
   * @param name - имя слоя
   * @param layers - список слоев
   */
  getLayerByName(name: string, layers?: BaseLayer[]): BaseLayer | undefined;

  /**
   * Подгоняет карту таким образом, чтобы фича отображалась на карте целиком
   * @param feature - фича
   * @param padding - боковые отступы (в пикселях)
   */
  fitMapToFeature(feature: Feature, padding?: number[]): void;


  onActivePlugin(plugin: string | undefined): void;
  addActivePlugin(pluginName: string | undefined, deactivate: () => void): void;
  removeActivePlugin(pluginName: string | undefined): void;
}

/** Шаг зумирования карты */
export const CHANGE_ZOOM_STEP = 1;

/** Скорость анимации зумирования карты */
export const CHANGE_ZOOM_DURATION = 250;

export const EMPTY_DEFAULT_CONTROLS_MAP = {
  zoom: false,
  attribution: false,
  rotateOptions: { autoHide: true },
};
