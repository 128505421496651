import { 
  BeaconIcon, 
  CameraIcon, 
  GcsIcon,
  BusIcon, 
  UavtwoIcon, 
  Antenna_2Icon, 
  SeaBabyDirIcon, 
  LancetDirIcon 
} from '@workspace/4Z1.uikit.react';
import { AssetTypes } from '@/entities/assets/model';
import type { ReactNode } from 'react';
import { AssetSubsetFragment } from '@/.graphql/graphql';
import {isDemoMode} from '@/shared/utils/isDemoMode';
import { Asset, KnownAssetUavTypes } from '@/entities/assets/model';

const DEMO = isDemoMode();

export const getAssetIconUav2 = (asset: Asset): ReactNode => {
  const uavType = asset.state?.uavState?.uavType;

  switch (uavType) {
    case KnownAssetUavTypes.UBoat:
      return <SeaBabyDirIcon/>;
    case KnownAssetUavTypes.Lancet:
      return <LancetDirIcon/>;
    default:
      return  <UavtwoIcon/>;
  }
  
}

/**
 * @deprecated какое-то старье, удалить нафиг )
 */
export const getAssetIconUav = (iconId: string, withStream: boolean): ReactNode => {
  return  DEMO ? <UavtwoIcon/> : iconId + (withStream ? 'plane_icon.svg' : 'plane_icon_yellow.svg')
}

export const getAssetIconDynamic = (item: AssetSubsetFragment, props?: any): ReactNode => {

  if (DEMO) {
    switch (item.assetType) {
      case AssetTypes.Car:
        return <BusIcon/>
      case AssetTypes.Ptz:
        return <CameraIcon/>
    }
  }

  let icon = '';
  if(item.assetType == AssetTypes.Ptz) icon = 'sova.svg';
  if(item.assetType == AssetTypes.Car) icon = 'grpc_car.svg';
  return item.iconId + icon;
}

export const getAssetIconStatic = (item: AssetSubsetFragment, props?: any): ReactNode => {
  if (DEMO) {
    switch (item.assetType) {
      case AssetTypes.Gcs:
        return <GcsIcon.Small/>
      case AssetTypes.Beacon:
        return <BeaconIcon/>
      case AssetTypes.Afu:
        return <Antenna_2Icon.Small/>
    }
  }

  let icon = '';
  switch (item.assetType) {
    case AssetTypes.Beacon:
      icon = 'beacon.svg';
      break;
    case AssetTypes.GeoGorizontService:
      icon = 'gg.svg';
      break;
    case AssetTypes.LocalServer:
      icon =  '4z1.svg';
      break;
    case AssetTypes.Ptz:
      icon =  'sova.svg';
      break;
    case AssetTypes.Gcs:
      icon =  'grpc_gcs.svg';
      break;
  }
  return item.iconId + icon;
}
