import {
  FlightAttachmentsDocument,
  FlightAttachmentsQuery,
  Photo,
  Timing,
  Video,
} from '@/.graphql/graphql';
import {
  attachmentIcons,
  AttachmentsAndGases,
  PhotoAttachment,
  VideoAttachment,
} from '@/entities/attachment';
import { AttachmentTypes } from '@/entities/attachment/model/utils';
import { GraphQlApi, REQUEST_POLICY_NETWORK } from '@/shared/api/graphql';
import { Location } from '@workspace/4Z1.ts.utils';
import { isNumber, omit } from 'lodash';
import moment from 'moment';

export interface FlightAttachmentsApi {
  readonly fetchAttachments: (flightId: string) => Promise<AttachmentsAndGases>;
}

class FlightAttachmentsImplApi implements FlightAttachmentsApi {
  private readonly clientGraphQl = new GraphQlApi();

  async fetchAttachments(flightId: string): Promise<AttachmentsAndGases> {
    const requestResult: FlightAttachmentsQuery = await this.clientGraphQl.query<FlightAttachmentsQuery>(
      FlightAttachmentsDocument,
      { id: flightId },
      REQUEST_POLICY_NETWORK,
    );

    const flight = requestResult.flight;

    const typedVideos = flight?.videos?.map(processAttachment(AttachmentTypes.Video)) ?? [];
    const typedPhotos = flight?.photos?.map(processAttachment(AttachmentTypes.Photo)) ?? [];

    const attachments = [...typedPhotos, ...typedVideos].toSorted((next, prev) => {
      if (next.datetime === prev.datetime) {
        return 0;
      }

      if (moment(next.datetime).isBefore(prev.datetime)) {
        return -1;
      }

      return 1;
    });

    return { attachments, gases: flight?.gazes ?? [] };
  }
}

export const createFlightAttachmentsApi = (): FlightAttachmentsApi => {
  return new FlightAttachmentsImplApi();
};

const transform = (value: string): readonly number[] => {
  return value.split(',').map((item) => {
    const parsedItem = Number(item);

    if (isNumber(parsedItem)) return parsedItem;

    throw new Error(
      `Cannot cast literal symbol to number - ${item} casted to ${parsedItem}`,
    );
  });
};

const processPhoto = (photo: Photo): PhotoAttachment => {
  if (!photo?.center || !photo?.center?.coordinates || !photo.image_box) {
    throw new Error(
      'Cannot process a photo with missing properties of center, coordinates or image_box',
    );
  }

  const resolutions = transform(photo.image_box.resolutions);
  const bbox = transform(photo.image_box.bbox);

  // Наша карта работает со значениями [lon, lat], а API возрващает [lat, lon]
  // Для корректной работы компонента трэк-мапы, необходимо развернуть координаты
  const location = Location.parse(photo.center.coordinates?.toReversed());

  if (!location) {
    throw new Error('Cannot get coordinates for location of unknown CRS');
  }

  return {
    ...omit(photo, 'thumb'),
    uniqueId: photo.id + AttachmentTypes.Photo,
    thumbUrl: photo.thumb,
    type: AttachmentTypes.Photo,
    center: location,
    geometry: photo.center.geom,
    placeholderIcon: attachmentIcons.Photo.placeholderComponent,
    icon: attachmentIcons.Photo.icon,
    image_box: {
      ...photo.image_box,
      resolutions: [...resolutions],
      bbox: [...bbox],
    },
  };
};

const processVideo = (video: Video): VideoAttachment => ({
  ...video,
  uniqueId: video.id + AttachmentTypes.Video,
  placeholderIcon: attachmentIcons.Video.placeholderComponent,
  icon: attachmentIcons.Video.icon,
  type: AttachmentTypes.Video,
  timing: video?.timing?.map((timing: Timing) => {
    return {
      direction: timing.dir,
      sec: timing.sec,
      droneCoordinates: GraphQlApi.parseCoordinates([timing.lon, timing.lat]),
      cameraCoordinates: GraphQlApi.parseCoordinates([
        timing.c_lon,
        timing.c_lat,
      ]),
    };
  }),
  firstCoord: GraphQlApi.parseCoordinates(video.track?.first_coord),
});

const TYPES = {
  [AttachmentTypes.Photo]: {
    requiredFields: ['image_box'],
    process: processPhoto,
  },
  [AttachmentTypes.Video]: {
    requiredFields: ['track'],
    process: processVideo,
  },
} as const;

const processAttachment =
  (type: AttachmentTypes) => (attachment: Photo | Video) => {
    const typeMeta = TYPES[type];

    if (!typeMeta.requiredFields.every((field) => field in attachment)) {
      throw new Error(
        `Unexpected format: not enough required fields in object ${attachment} ${typeMeta.requiredFields}`,
      );
    }

    // TODO - На этом этапе мы уже точно знаем, с каким из объектов мы работаем, исправить ошибку Typescript'а
    return typeMeta.process(attachment as Photo & Video);
  };
