import { isNil } from 'lodash';

export enum AttachmentTypes {
  Video = 'Video',
  Photo = 'Photo',
}

/**
 * Параметр для всех открываемых вложений полёта в режиме просмотра
*/
export const URL_PARAM_ATTACHMENT_ID = 'attachmentId';

/**
 * Параметр для перехода к конкретному моменту видео (в секундах)
*/
export const URL_PARAM_VIEWTIME = 'time';

/**
 * Параметр типа открываемого вложения
*/
export const URL_PARAM_ATTACHMENT_TYPE = 'type';

/**
 * Генерирует ссылку для открытия вложения  полёта
 * @param attachmentId - идентификатор вложения
 * @param type {photo | video} - тип вложения
 * @param timeSeek - время для промотки видео (в секундах)
*/
export const getAttachmentQueryParams = (
  attachmentId: string,
  type = AttachmentTypes.Photo,
  timeSeek?: number | null,
): string => {
  const url = new URLSearchParams();
  
  url.set(URL_PARAM_ATTACHMENT_ID, attachmentId);
  
  url.set(URL_PARAM_ATTACHMENT_TYPE, type);

  if (!isNil(timeSeek) && type === AttachmentTypes.Video) {
    url.set(URL_PARAM_VIEWTIME, timeSeek.toString());
  }
  
  return `?${url.toString()}`;
};

export enum AttachmentUrlParams {
  Id = URL_PARAM_ATTACHMENT_ID,
  Viewtime = URL_PARAM_VIEWTIME,
  Type = URL_PARAM_ATTACHMENT_TYPE,
}