/**
 * Утилита для скачивания файлов с веб-ресурсов
 *
 * Позволяет скачивать файлы находящиеся вне текущего домена.
 *
 * @param {string} fileName - Имя, которое будет присвоено файлу при загрузке
 * @param {string} url - URL для скачивания файла
 */
export const downloadFile = async (
  url: string,
  fileName: string,
): Promise<void> => {
  return await fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    });
};
