import { AssetTypes } from "@/.graphql/graphql";
import { Asset } from "@/entities/assets/model";
import { getAssetIconDynamic, getAssetIconStatic, getAssetIconUav2 } from "@/shared/utils/getAssetIcon";

export function getAssetIcon(item: Asset){
    switch (item.assetType){
      case AssetTypes.Uav:
        return getAssetIconUav2(item);
      case AssetTypes.Gcs:
          return getAssetIconStatic(item);
      case AssetTypes.Beacon:
        return getAssetIconStatic(item);
      case AssetTypes.GeoGorizontService:
        return getAssetIconStatic(item);
      case AssetTypes.Car:
        return getAssetIconDynamic(item);
      case AssetTypes.Ptz:
        return getAssetIconDynamic(item);
      case AssetTypes.Afu:
        return getAssetIconStatic(item);
    }
  }
