import { useNavigate, useLocation } from 'react-router-dom';

const useNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getBasePath = () =>
    location.pathname.substring(0, location.pathname.lastIndexOf('/') + 1);
  const getLocationKey = () =>
    location.pathname.slice(location.pathname.lastIndexOf('/') + 1);

  const onTabClick = (key: string) => {
    const basePath = getBasePath();
    navigate(`${basePath}${key}`);
  };

  return {
    getLocationKey,
    onTabClick,
  };
};

export default useNavigation;
