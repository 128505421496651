import { makeAutoObservable } from 'mobx';
import { inject, injectable } from 'inversify';
import { FileItem } from '@/components/FileList/model/interface';
import { UploadService, UploadSession } from '@/entities/fileUpload';
import { logger } from '@workspace/4Z1.ts.utils';
import { partition } from 'lodash';

const log = logger('UPLF:S');

const USB_UPLOAD_ENABLED = true;

interface SessionInfo {
  readonly files: readonly FileItem[];
  readonly title?: string;
  readonly badgeCount: number;
  readonly onClose?: () => void;
  readonly error?: string;
}

@injectable()
export class UploaderStore {
  public static readonly diKey = Symbol.for('UploaderStore');

  private readonly service: UploadService;

  constructor(
    @inject(UploadService.diKey) service: UploadService,
  ) {
    this.service = service;
    makeAutoObservable(this);
  }

  public mount(): void {
    // do nothing
  }

  public unmount(): void {
    this.service.cleanUp();
  }

  public get isUsbUploadEnabled(): boolean {
    if (!USB_UPLOAD_ENABLED) {
      return false;
    }
    const hasActiveUsbSessions = this.service.allSessions.some(session => session.type === 'usb');
    return !hasActiveUsbSessions;
  }

  public get isLoading(): boolean {
    return this.service.hasNewSessions;
  }

  public get sessions(): readonly SessionInfo[] {
    const allSessions = this.service.allSessions;

    const [usbSessions, otherSessions] = partitionSessions(allSessions);

    const usbSessionItems = mapUsbSessions(usbSessions, this.service);
    const otherItems = otherSessions.flatMap(session => session.files);

    const otherSessionItem = createOtherSessionItem(otherItems, otherSessions);

    return [
      ...usbSessionItems,
      ...(otherSessionItem ? [otherSessionItem] : [])
    ];
  }

  public get uploadsTotalPercentage(): number {
    return this.service.percentage;
  }

  public onFilesSelected(files: readonly File[]): void {
    this.service.uploadFiles({ type: 'files', files });
  }

  public onUsbUploadClicked(): void {
    this.service.uploadFiles({ type: 'usb' });
  }
}

function partitionSessions(allSessions: readonly UploadSession[]): [readonly UploadSession[], readonly UploadSession[]] {
  return partition(allSessions, session => session.type === 'usb');
}

function mapUsbSessions(usbSessions: readonly UploadSession[], service: UploadService): readonly SessionInfo[] {
  return usbSessions.map(session => ({
    files: session.files,
    title: 'massload.uploadUSB',
    badgeCount: session.files.length,
    onClose: session.isFinished ? () => service.cleanUsb() : undefined,
    error: session?.requestErrorMessage,
  }));
}

function createOtherSessionItem(otherItems: readonly FileItem[], sessions: readonly UploadSession[]): SessionInfo | undefined {
  if (otherItems.length > 0) {
    const error = sessions.map(session => session.requestErrorMessage).find(error => error !== undefined);
    return {
      files: otherItems,
      badgeCount: otherItems.length,
      error: error
    };
  }
  return undefined;
}